<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            4. Запрещенные действия
          </v-card-title>
        </v-col>
        <v-col class="d-flex flex-column justify-center" cols="4">
          <v-card class="d-flex flex-column justify-center">
              <v-img
                lazy-src="img/material/monitor.svg"
                max-width="300"
                height="300"
                src="img/material/monitor.svg"
                class="ma-10 pa-5"
                contain
              ></v-img>

          </v-card>
          <v-dialog
            v-model="dialog1"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                class="pa-10 mt-5 red"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Для электрооборудования <br> и электроприборов
              </v-btn>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline" dark>
                Запрещенные действия для электрооборудования и электроприборов:
              </v-card-title>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                часто включать и выключать оборудование без необходимости;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                прикасаться к экрану и к тыльной стороне блоков компьютера;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                работать на оборудовании мокрыми руками;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                выдергивать вилку из розетки за шнур, усилие должно быть приложено к корпусу вилки;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                класть на оборудование посторонние предметы;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                оставлять приборы без присмотра включенными в электрическую сеть. Исключение: приборы, которые могут находиться в круглосуточном режиме в соответствии с инструкцией завода-изготовителя;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                проверять работоспособность оборудования в неприспособленных помещениях с токопроводящими полами, сырых помещениях, не позволяющих заземлить металлические части;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                самостоятельно проводить ремонт оборудования, его производят специалисты-техники с соблюдением технических требований;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                одновременно касаться электроприборов и металлоконструкций, соединенных с землей;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                эксплуатировать поврежденные электроустановочные изделия (розетки, рубильники и прочие);
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                руководители загородных оздоровительных учреждений для детей и подростков;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                пользоваться приемниками электроэнергии в условиях, не соответствующих требованиям инструкций от производителя;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                очищать от пыли и загрязнения электрооборудование под напряжением;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                использовать некалиброванные плавкие вставки или другие самодельные аппараты защиты от перегрузки и короткого замыкания.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog1 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col class="d-flex flex-column justify-center" cols="4">
          <v-card class="d-flex justify-center">
              <v-img
                lazy-src="img/material/light.svg"
                max-width="300"
                height="300"
                src="img/material/light.svg"
                contain
                class="ma-10 pa-5"
              ></v-img>
          </v-card>
          <v-dialog
            v-model="dialog2"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                class="pa-10 mt-5 red"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Для освещения
              </v-btn>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline" dark>
                Запрещенные действия для освещения:
              </v-card-title>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                оборачивать светильники (с лампами накаливания) и электролампы бумагой, тканью или иными горючими материалами;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                эксплуатировать светильники со снятыми колпаками (рассеивателями), предусмотренными конструкцией;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                использовать электрические лампы накаливания большой мощности;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                в помещениях с горючими материалами или изделиями в сгораемой упаковке применять электросветильники со стеклянными глухими колпаками (плафонами);
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                соприкасание электросети и электросветильников со сгораемыми конструкциями зданий и сооружений, горючими материалами.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog2 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col class="d-flex flex-column justify-center" cols="4">
          <v-card class="d-flex justify-center">
              <v-img
                lazy-src="img/material/cable.svg"
                max-width="300"
                height="300"
                src="img/material/cable.svg"
                class="ma-10 pa-5"
                contain
              ></v-img>
          </v-card>
          <v-dialog
            v-model="dialog3"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                class="pa-10 mt-5 red"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Для проводов и кабелей
              </v-btn>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline" dark>
                Запрещенные действия для проводов и кабелей:
              </v-card-title>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                вешать что-либо на провода и шнуры, закрашивать их и белить, закладывать за газовые и водопроводные трубы, за батареи отопительной системы;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                использовать электропровода и кабели с видимыми нарушениями изоляции и со следами термического воздействия;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                использовать временную электропроводку (включая удлинители, сетевые фильтры, не предназначенные по своим характеристикам для питания применяемых электроприборов). Исключение: иллюминационные установки и электропроводка, электросети, питающие места проведения временных ремонтных и строительно-монтажных работ;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                прокладывать воздушные линии электропередач, электропровода и кабели транзита над сгораемыми конструкциями зданий и горючими материалами;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                прокладывать электрическую проводку по горючему основанию либо наносить (наклеивать) горючие материалы на электрическую проводку;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                допускать провисание электропроводов, их соприкосновение между собой и с конструкционными элементами зданий и сооружений при ненадлежащей изоляции;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                оставлять неизолированные концы электропроводов и электрокабелей.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog3 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="12">
          <Hint></Hint>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    dialog1: false,
    dialog2: false,
    dialog3: false
  }),
  components: {
    Hint
  }
}
</script>
